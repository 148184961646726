import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { InputProps } from "@material-ui/core/Input";
import { createRef } from "react";
import { getStorageData } from "../../../../framework/src/Utilities";
import { ICreateBrandErrorReponse, ICreateBrandSuccessReponse } from "./Interfaces";
import { toast } from "react-toastify";
import * as Yup from "yup";

interface IBrandRes {
  id:string,
  type:string,
  attributes:{
    id:number,
    brand_name:string,
    brand_name_arabic:string,
    brand_website:string,
    brand_year: number,
    account_id : number,
    branding_tradmark_certificate:string,
    brand_image:null,
    approve:boolean,
    restricted:boolean,
    gated:boolean,
    created_at:string,
    updated_at:string
  },
  updateFlag:boolean
}

interface FormValues {
  brand_name: string,
  brand_website: string,
  brand_year: number | string,
  branding_tradmark_certificate: string
}

interface IEditData {
  id: number,
    attributes: {
      id: number,
      brand_name: string
    },
  updateFlag: boolean
}

interface IData {
  updateFlag: boolean,
  attributes: {
    id: string,
    brand_name: string,
    brand_year: string | number,
    brand_name_arabic: string,
    branding_tradmark_certificate: string,
    brand_website: string,
  }
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  editData: IEditData;
  toRender: (flag: boolean) => void;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  brand_name: string;
  brand_name_arabic: string;
  brand_year: string;
  brand_website: string;
  branding_tradmark_certificate: string;
  errorMsg: string;
  successMsg: string;
  authToken: string;
  editData: IData;
  createBrandFromAddProduct: boolean;
  openPdfFullView: boolean;
  openSuccessModal: boolean
  dragging: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerBrandCatalogController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  inputBrandRef: React.RefObject<InputProps & { click: Function }>;
  apiCreateBrandCallId: string = "";
  apiUpdateBrandCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      brand_name: "",
      brand_name_arabic: "",
      brand_year: "",
      brand_website: "",
      branding_tradmark_certificate: "",
      errorMsg: "",
      successMsg: "",
      authToken: "",
      editData: {
        updateFlag: false,
        attributes: {
          id: '',
          brand_name: '',
          brand_year: '',
          brand_name_arabic: '',
          branding_tradmark_certificate: '',
          brand_website: ''
        }
      } ,
      createBrandFromAddProduct: false,
      openPdfFullView: false,
      openSuccessModal: false,
      dragging:false
    };

    this.inputBrandRef = createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const createBrandFromAddProduct = message.getData(getName(MessageEnum.createBrandFromAddProduct)) || false;
      const rowData = localStorage.getItem("rowData");
      const brandData = JSON.parse(rowData as string);
      this.handleSetData(createBrandFromAddProduct, brandData as IData);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      this.handleResponse(apiRequestCallId, responseJson);
    }
  }
  // Customizable Area End

  // Customizable Area Start
  validationSchema = Yup.object().shape({
    brand_name: Yup.string()
      .required("Brand name is required")
      .matches(/^(?=.*[a-zA-Z]).{3,256}$/, "Please enter valid brand name")
      .min(3, "Brand name must be at least 3 characters")
      .max(256, "Brand name must not exceed 256 characters"),
    brand_website: Yup.string()
      .nullable()
      .matches(configJSON.imageUrlRegex, 'Website must start with "https://" or "www."')
      .max(256, "Website must not exceed 256 characters")
      .required("Brand website is required"),
    branding_tradmark_certificate: Yup.mixed()
      .required("Please upload an document.")
      .test("fileType", "Invalid file type. Only PDF files are allowed.", value => {
        if (!value) return true; // Allow undefined values (not selected file)
        if (typeof value === "string") {
          return value.startsWith("https://");
        } else {
          return value.type === "application/pdf";
        }
      })
      .test("fileSize", "Please upload a file less than 5MB.", value => {
        if (typeof value === "string") {
          // This is a string URL, so no file size check required
          return true;
        } else {
          return !value || value.size / (1024 * 1024) <= 5;
        }
      }),
    brand_year: Yup.number()
      .required("Brand year is required")
      .min(1900, "Please enter valid brand year")
      .max(new Date().getFullYear(), "Please enter valid brand year")
  });

  handleResponse = (apiRequestCallId: string, responseJson: IBrandRes) => {
    if (apiRequestCallId === this.apiUpdateBrandCallId) {
      this.handleUpdateBrandResponse(responseJson);
    }
    if (apiRequestCallId === this.apiCreateBrandCallId) {
      this.handleCreateBrandResponse(responseJson);
    }
  };

  handleSetData = (createBrandFromAddProduct: boolean, brandData: IData) => {
    this.setState({ createBrandFromAddProduct });
    if (createBrandFromAddProduct) {
      this.setState({ editData: {
        updateFlag: false,
        attributes: {
          id: '',
          brand_name: '',
          brand_year: '',
          brand_name_arabic: '',
          branding_tradmark_certificate: '',
          brand_website: ''
        }
      } });
      return;
    }

    if (brandData) {
      this.setState({
        brand_name: brandData.attributes.brand_name,
        brand_name_arabic: brandData.attributes.brand_name_arabic || "",
        brand_year: brandData.attributes.brand_year as string ,
        brand_website: brandData.attributes.brand_website || "",
        branding_tradmark_certificate: brandData.attributes.branding_tradmark_certificate,
        editData: brandData
      });
    } else {
      this.setState({ brand_name: "", brand_name_arabic: "", brand_year: "", brand_website: "", branding_tradmark_certificate: "", editData: {
        updateFlag: false,
        attributes: {
          id: '',
          brand_name: '',
          brand_year: '',
          brand_name_arabic: '',
          branding_tradmark_certificate: '',
          brand_website: ''
        }
      } });
    }
  };

  handleUpdateBrandResponse = (myresponseJson: unknown) => {
    const mySuccessData = myresponseJson as ICreateBrandSuccessReponse;
    const myErrorData = myresponseJson as ICreateBrandErrorReponse;
    this.setState({ errorMsg: "", branding_tradmark_certificate: "" });
    if ("data" in mySuccessData) {
      toast.success("Brand updated successfully!", {
        progress: undefined,
        autoClose: 3000, // milliseconds
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        pauseOnHover: true
      });
      setTimeout(() => {
        this.props.navigation.navigate(configJSON.sellerHomeText, { path: configJSON.brandManagementMenu });
      }, 2000);
    } else if ("errors" in myErrorData) {
      toast.error(myErrorData.errors[0], {
        draggable: true,
        progress: undefined,
        autoClose: 3000, // milliseconds
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false,
        position: "top-right"
      });
    } else {
      toast.error("Something went wrong. Please try again later!", {
        draggable: true,
        progress: undefined,
        autoClose: 3000, // milliseconds
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false,
        position: "top-right"
      });
    }
  };

  handleCreateBrandResponse = (responseJson: unknown) => {
    const successData = responseJson as ICreateBrandSuccessReponse;
    const errorData = responseJson as ICreateBrandErrorReponse;
    this.setState({ errorMsg: "", branding_tradmark_certificate: "" });
    if ("data" in successData) {
      this.setState({openSuccessModal: true})

      setTimeout(() => {
        if (this.state.createBrandFromAddProduct) {
          this.setState({openSuccessModal: false})
          this.props.navigation.navigate(configJSON.sellerHomeText, { path: configJSON.searchBrandMenu });
          const message: Message = new Message(getName(MessageEnum.NavigationSellerDashboardMessage));
          message.addData(getName(MessageEnum.ChangeMenuPanel), configJSON.searchBrandMenu);
          message.addData(getName(MessageEnum.BrandData), successData?.data);
          this.send(message);
        } else {
          this.setState({openSuccessModal: false})
          this.props.navigation.navigate(configJSON.sellerHomeText, { path: configJSON.brandManagementMenu });
        }
        localStorage.removeItem("rowData");
      }, 3000);
    } else if ("errors" in errorData) {
      toast.error(errorData.errors[0], {
        draggable: true,
        progress: undefined,
        autoClose: 3000, // milliseconds
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false,
        position: "top-right"
      });
    } else {
      toast.error("Something went wrong. Please try again later!", {
        draggable: true,
        progress: undefined,
        autoClose: 3000, // milliseconds
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false,
        position: "top-right"
      });
    }
  };

  handleOpenFileInput = () => {
    if (this.inputBrandRef && this.inputBrandRef.current) {
      this.inputBrandRef.current.click();
    }
  };

  handleOpenPDFModal = () => {
    this.setState({ openPdfFullView: true });
  };

  handleClosePDFModal = () => {
    this.setState({ openPdfFullView: false });
  };

  pdfFilename = (url: string | {name?: string}) => {
    if (typeof url === 'string') {
      const urlParts = url.split("/");
      const pdfName = urlParts[urlParts.length - 1];

      return decodeURIComponent(pdfName.replace(/\+/g, " ")) || "";
    } else if (url.name) {
        return url.name;
    } 
  };
  createBrand = (values: FormValues) => {
    const headers = { token: this.state.authToken };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    if (this.state?.editData?.updateFlag) {
      this.apiUpdateBrandCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_catalogue/brands/update_seller_brands");
      const formData = new FormData();
      formData.append("brand_name", values.brand_name);
      formData.append("id", this.state.editData.attributes.id);
      formData.append("brand_website", values.brand_website);
      formData.append("brand_year", values.brand_year as string);
      if (typeof values.branding_tradmark_certificate !== "string") {
        formData.append("branding_tradmark_certificate", values.branding_tradmark_certificate);
      }
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    } else {
      this.apiCreateBrandCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateBrandEndpoint);
      const formData = new FormData();
      formData.append("brand_name", values.brand_name);
      formData.append("brand_website", values.brand_website);
      formData.append("brand_year", values.brand_year as string);
      formData.append("branding_tradmark_certificate", values.branding_tradmark_certificate);

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    }

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetToken = async () => {
    const authToken = await getStorageData("authToken");
    this.setState({ authToken });
  };

  async componentDidMount(): Promise<void> {
    this.handleGetToken();
  }

  handleCloseSuccessModal = () => {
    this.setState({openSuccessModal: false})
  }
  
  handleDragLeave = (event: React.DragEvent) => {
    this.setState({ dragging: false });
  };
  
  handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ dragging: true });
  };
  
  handleDrop = (event: React.DragEvent,setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,setFieldValue: (field: string, value: any, shouldValidate?: boolean)=>void) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ dragging: false });
    const fileT = event.dataTransfer.files[0];
    if (fileT) {
      setFieldValue("branding_tradmark_certificate", fileT);
      setFieldTouched("branding_tradmark_certificate", true, false);
    }
  };
  
  // Customizable Area End
}
