import React from "react";

// Customizable Area Start
import { Box, InputAdornment, Grid, Button, Input, Typography, Modal, IconButton } from "@material-ui/core";
import { BoxNoScroll, CustomizeBrandInput } from "../CustomizedElementStyle";
import { file } from "../assets";
import { errorImage } from "../../../bulkuploading/src/assets";
import CloseIcon from "@material-ui/icons/Close";
import { Form, Formik } from "formik";
import { ToastContainer } from "react-toastify";
import ClearIcon from "@material-ui/icons/Clear";
// Customizable Area End

import SellerBrandCatalogController, { Props, configJSON } from "./SellerBrandCatalogController";
import { CustomBox } from "../../../otp-input-confirmation/src/CustomElementStyling";
import { successImg } from "../../../catalogue/src/assets";
import { CustomOrangeButton } from "../../../../components/src/CommonButton";

export default class SellerSuggestionFeedback extends SellerBrandCatalogController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  pdfModal = (file: string) => {
    return (
      <Modal open={this.state.openPdfFullView} data-test-id="deleteModalRef">
        <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center " }} style={{ background: "rgba(0,0,0,0.1)" }}>
          <Box sx={{ width: "75%", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", borderRadius: "8px", padding: "50px", position: "relative" }} style={{ background: "white" }}>
            <Box
              sx={{
                position: "absolute",
                top: "8px",
                right: "20px"
              }}
            >
              <IconButton aria-label="toggle pdf visibility" edge="end">
                <CloseIcon data-test-id="closeIcon" onClick={() => this.handleClosePDFModal()} />
              </IconButton>
            </Box>
            <iframe src={typeof file === "string" ? file : URL.createObjectURL(file)} title="PDF Viewers" style={{ width: "100%", height: "60vh", border: "none" }}></iframe>
          </Box>
        </Box>
      </Modal>
    );
  };

  successModal = () => {
    return(
      <Modal data-test-id="successModal" open={this.state.openSuccessModal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <CustomBox data-test-id="successModal" sx={webStyles.modalWrapper}>
          <CustomBox sx={webStyles.modalContainer}>
            <Box sx={webStyles.closeIcon}>
          <IconButton onClick={this.handleCloseSuccessModal} data-test-id="closeIcon">
            <ClearIcon style={webStyles.clearIcon} />
          </IconButton>
            </Box>
            <CustomBox sx={webStyles.customBox}>
              <CustomBox sx={webStyles.imageWrapper}>
                <CustomBox sx={webStyles.imageContainer}>
                  <img src={successImg} alt="success image" />
                </CustomBox>
                <CustomBox style={webStyles.header}>{configJSON.brandSuccesstext}</CustomBox>
                <CustomBox style={webStyles.subHeader}>{configJSON.approvalPendingText}</CustomBox>
              </CustomBox>
            </CustomBox>
          </CustomBox>
        </CustomBox>
      </Modal>
    )
  }

  returnError = (touched: boolean | undefined, errors: string | undefined) => {
    if (touched && errors) {
      return <Typography style={webStyles.errorStyle}>{errors}</Typography>;
    } else {
      return null;
    }
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <ToastContainer />
        <Box sx={{ padding: "20px 0px 15px 0px", fontSize: "18px", fontWeight: 400, fontFamily: `"Lato", sans-serif` }}>Brand Management</Box>
        <BoxNoScroll style={{ paddingBottom: 20, overflow: "auto" }}>
          <Formik
            initialValues={{
              brand_name: this.state.brand_name,
              brand_website: this.state.brand_website,
              brand_year: this.state.brand_year,
              branding_tradmark_certificate: this.state.branding_tradmark_certificate
            }}
            enableReinitialize={true}
            validationSchema={this.validationSchema}
            validateOnMount={true}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={this.createBrand}
            data-test-id="formik"
          >
            {({ handleChange, errors, setFieldTouched, touched, setFieldValue, handleBlur, values, setErrors }) => (
              <Form
                translate={undefined}
              >
                <Box style={{ borderRadius: "8px", display: "flex", flexDirection: "column", padding: "30px", overflowY: "auto", overflowX: "hidden", position: "relative", background: "white" }}>
                  <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", marginBottom: 10 }}>
                    <Box sx={{ fontSize: "27px", fontWeight: 700, fontFamily: `"Lato", sans-serif` }}>Create Brand</Box>
                  </Box>
                  <Box style={{ width: "calc(100% + 60px)", height: 1, background: "#EEEEEE", position: "absolute", left: 0, top: 100 }}></Box>
                  <Box sx={{ width: "100%", height: 90 }}></Box>
                  <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <CustomizeBrandInput
                        data-test-id="brandNameRef"
                        label="Brand Name*"
                        id="outlined-start-adornment"
                        fullWidth
                        inputProps={{
                          maxLength: 15
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"></InputAdornment>
                        }}
                        name="brand_name"
                        value={values.brand_name}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                          handleChange(event);
                          setFieldTouched("brand_name", true, false);
                        }}
                        onBlur={handleBlur}
                      />
                      {this.returnError(touched.brand_name, errors.brand_name)}
                    </Grid>
                  </Grid>
                  <Box sx={{ width: "100%", height: 55 }}></Box>
                  <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <CustomizeBrandInput
                        data-test-id="websiteRef"
                        label="Brand Website (Social media link)*"
                        id="outlined-start-adornment"
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"></InputAdornment>
                        }}
                        name="brand_website"
                        value={values.brand_website}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>)=> {
                          handleChange(event);
                          setFieldTouched("brand_website", true, false);
                        }}
                        onBlur={handleBlur}
                      />
                      {this.returnError(touched.brand_website, errors.brand_website)}
                    </Grid>
                  </Grid>
                  <Box sx={{ width: "100%", height: 55 }}></Box>
                  <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <CustomizeBrandInput
                        data-test-id="brandYearRef"
                        label="Brand Year*"
                        id="outlined-start-adornment"
                        type="number"
                        fullWidth
                        inputProps={{
                          type: "number",
                          maxLength: 4
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"></InputAdornment>
                        }}
                        name="brand_year"
                        value={values.brand_year}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                          handleChange(event);
                          setFieldTouched("brand_year", true, false);
                        }}
                        onBlur={handleBlur}
                      />
                      {this.returnError(touched.brand_year, errors.brand_year)}
                    </Grid>
                  </Grid>
                  <Box sx={{ marginTop: 30, color: "#9a9a9a", fontFamily: `"Lato", sans-serif`, fontSize: 12, fontWeight: 600 }}>Branding & Trademark Certificate*</Box>
                  <Input
                    data-test-id="inputFIleBrandRef"
                    type="file"
                    style={{
                      display: "none"
                    }}
                    value=""
                    inputProps={{
                      "aria-label": "upload documents",
                      ref: this.inputBrandRef,
                      accept: ".pdf"
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue("branding_tradmark_certificate", event.currentTarget?.files?.[0]);
                      setFieldTouched("branding_tradmark_certificate", true, false);
                    }}
                    name="branding_tradmark_certificate"
                  />
                  <label htmlFor="upload-button">
                    <Button
                      variant="outlined"
                      fullWidth
                      data-test-id="openFileRef"
                      onClick={this.handleOpenFileInput}
                      style={{
                        width: "100%",
                        height: 300,
                        padding: "20px 0px",
                        margin: "10px 0px",
                        border: this.state.dragging?"1px solid orange":"none",
                        backgroundImage: this.state.dragging ? "": `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FBBC98FF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                        borderRadius: "5px",
                        textTransform: "none",
                        fontFamily: `"Lato", sans-serif`
                      }}
                      onDragOver={this.handleDragOver} 
                      onDragLeave={this.handleDragLeave}
                      onDrop={(event:React.DragEvent)=>this.handleDrop(event,setFieldTouched,setFieldValue)}
                    >
                      <Box>
                        <img src={file} />
                        <Box sx={{ fontSize: "27px", fontWeight: 700, fontFamily: `"Lato", sans-serif` }}>Drop or upload your file</Box>
                        <Box sx={{ color: "#9a9a9a", fontFamily: `"Lato", sans-serif`, fontSize: 16 }}>Only PDF files allowed to be uploaded here</Box>
                      </Box>
                    </Button>
                  </label>
                  {this.returnError(touched.branding_tradmark_certificate, errors.branding_tradmark_certificate)}
                  <Box sx={{ width: "100%", height: 60 }}>
                    {values.branding_tradmark_certificate && (
                      <Grid item sm={6} xs={12}>
                        <Box style={{ justifyContent: "space-between", display: "flex", alignItems: "center", background: "#f5f5f5", borderRadius: 5, flexWrap: "nowrap", padding: "0px 0px 0px 20px" }}>
                          <Box data-test-id="openPdf" onClick={this.handleOpenPDFModal} style={{ cursor: "pointer" }} sx={{ marginBottom: "10px", textAlign: "start", fontFamily: `"Lato", sans-serif` }}>
                            File: {this.pdfFilename(values.branding_tradmark_certificate)}
                          </Box>
                          <IconButton onClick={() => setFieldValue("branding_tradmark_certificate", null)} data-test-id="removeButtonRef">
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    )}
                    {this.state.errorMsg && (
                      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "no-wrap", alignItems: "center" }}>
                        <Box sx={{ marginRight: "10px" }}>
                          <img src={errorImage} />
                        </Box>
                        <Box>
                          <Typography variant="body1" style={{ color: "#D74D4D" }}>
                            {this.state.errorMsg}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <CustomOrangeButton
                      data-test-id="submitRef"
                      type="submit"
                      style={{
                        textTransform: "none",
                        fontWeight: 700,
                        fontSize: 16,
                        background: "#FF6008",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "8px"
                      }}
                    >
                      Submit
                    </CustomOrangeButton>
                  </Box>
                </Box>
                {values.branding_tradmark_certificate && this.pdfModal(values.branding_tradmark_certificate)}
              </Form>
            )}
          </Formik>
        </BoxNoScroll>
        {this.successModal()}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const webStyles = {
  errorStyle: {
    color: "red",
    textAlign: "left" as const,
    fontSize: "12px",
    marginTop: "5px",
    fontFamily: `"Lato", sans-serif`
  },
  modalWrapper: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  },
  imageWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  imageContainer: {
    width: "100px",
    height: "100px",
    marginBottom: "24px"
  },
  modalContainer: {
    width: "350px",
    maxWidth: "350px",
    borderRadius: "10px",
    position: "relative" as const,
    padding: "40px",
    display: "flex",
    background: "white", 
    height: "280px",
    margin: "10px 15px"
  },
  customBox: { 
    width: "100%", 
    height: "100%", 
    display: "flex", 
    justifyContent: "center", 
    flexDirection: "column" as const 
  },
  header:{ 
    marginBottom: "10px", 
    textAlign: "center" as const,
    fontWeight: "bold" as const, 
    fontSize: "20px", 
    color: "#565656" 
  },
  subHeader: { 
    marginBottom: "30.1px", 
    fontSize: "12px", 
    color: "#565656" 
  },
  closeIcon: {
    position: "absolute" as const,
    top: "28px",
    right: "28px",
    boxShadow: "0px 3px 6px rgba(86, 86, 86, 0.1)",
    borderRadius: "100%",
  },
  clearIcon: { 
    color: "#3E3E3E" 
  }
};
// Customizable Area End
