import React from "react";

// Customizable Area Start
import {
    Box, Typography, withStyles,
    Grid,
    Container,
    Divider,
    Select,
    Theme,
    styled
} from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ProductCatalogueController, { IBrandList, Props } from "./ProductCatalogueController.web";
import CategoriesSideBar from "./CategoriesSideBar.web";
import ProductList from "./ProductList.web";
import HeaderWeb from "../../../landingpage/src/Header.web";
import FooterWeb from "../../../landingpage/src/Footer.web";
import { FormControlTwo, MenuItemTwo } from "../../../dashboard/src/CustomizedElementStyle";
import { withRouter } from 'react-router-dom';
import { backIcon, logoImg, nextIcon } from "../assets";
import { ClickableBox } from "../../../mobile-account-login/src/CustomElementStyling";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import ListViewProduct from "../ListViewProduct.web";
import AppsIcon from '@material-ui/icons/Apps';
import ViewListRoundedIcon from '@material-ui/icons/ViewListRounded';
import { Pagination } from "@material-ui/lab";
const ShopByBrandSlider = styled(Slider)({
    "& .slick-slide > div > div": {
      borderRadius: 10
    },
    "& .slick-slide > div": {
      display: "flex",
      margin: "0 8px",
      gap: 10
    },
    "& .slick-prev::before": {
        fontSize: 35,
        opacity: 1,
        color: "rgb(255, 255, 255)"
    },
    "& .slick-next::before": {
        fontSize: 35,
        opacity: 1,
        color: "rgb(255, 255, 255)"
    },
    "& .slick-slide": {
      height: "unset",
      padding: "0 20px",
    },
    "& .slick-track": {
        display: "flex"
    },
    "& .slick-prev": {
      top: "42%",
      color: "#000",
      backgroundColor: "#fff",
      padding: "4px",
      fontSize: "18px",
      borderRadius: '50%'
    },
    "& .slick-next": {
      top: "42%",
      color: "#000",
      backgroundColor: "#fff",
      padding: "4px",
      fontSize: "18px",
      borderRadius: '50%',
      "& >path": {
      }
    },
    "& .slick-disabled":{
        color:"gray",
        cursor:"default"
    }
  });
// Customizable Area End


class ProductCatalogue extends ProductCatalogueController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderGrid = () => {
        const {classes} = this.props;
        return(
            this.state.listView ? 
            <Box className={classes.displayFilter}>{"Grid"}
                <AppsIcon data-test-id="gridView" onClick={this.handleView} style={{ cursor:'pointer',color: "#eb6b2e", fontSize: '40px', marginLeft:'-5px', marginTop:'10px' }} />
            </Box> :
            <Box className={classes.displayFilter}>{"List"}
                <ViewListRoundedIcon data-test-id="listView" onClick={this.handleView} style={{ cursor:'pointer', color: "#eb6b2e", fontSize: '40px',  marginLeft:'-5px', marginTop:'10px' }} />
            </Box>
        )
    }

    renderProdPagination = () => {
        const {classes} = this.props;
        const setCount = this.getProdRoundedCount()
        return(
            this.state.productList.data?.length > 0 &&
            <Pagination
              page={this.state.pageNumber}
              data-test-id="prodPagination"
              count={setCount}
              classes={{ root: classes.pagination }}
              onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                this.setState({ pageNumber: value }, () => this.handleProductlistContent({}));
              }}
              shape="rounded"
              variant="outlined"
              color="primary" 
              siblingCount={0}
              boundaryCount={1}
            />
        )
      }

    renderProductList = () => {
        return(
            this.state.productList.data && this.state.productList.data.map((product: any) => {
                return(
                !this.state.listView ?
                <Grid item xs={12} sm={6} md={3} key={product.id}>
                    <ProductList navigation={this.props.navigation} id="product-list-categories" item={product}/>
                </Grid>
                :
                <Grid item xs={12} sm={12} md={12} key={product.id}>
                <ListViewProduct navigation={this.props.navigation} id="list-view-product" product={product}/>
            </Grid>
                )
            })
        )
    }

    renderTrending() {
        const settings = {
            className: "center",
            centerMode: false,
            infinite: true,
            dots: false,
            centerPadding: "60px",
            slidesToShow: 5,
            speed: 500,
            rows: 1,
            slidesPerRow: 1,
            padding: "10px 0px",
            boxShadow: "0px 1px 3px #EBEBEB",
            prevArrow: <img src={backIcon} />,
            nextArrow: <img src={nextIcon} />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,

                    }
                },
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,

                    }
                },
                {
                    breakpoint: 786,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,

                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ],
        };

        const { classes } = this.props;
        return (
            <Box id="trendingProducts" style={{paddingTop: "12px"}}>
                <div>
                    <h2 style={webStyle.trending}>Trending Products</h2>
                    <div style={webStyle.trendingWrapper}>
                        <div className={classes.itemSliderWrraper}>
                            <div className={classes.trendingTemplate}>
                                <CustomSlider {...settings}>
                                    {this.state.productListOne?.map((item, index) => {
                                        const { product_title, product_image } = item?.data?.attributes
                                        return (
                                            <div key={index}>
                                                <div id="listOne" onClick={() => this.handleTrendingProducts(item.data.id)} style={webStyle.trendingProductsSlider}>
                                                    <img src={product_image} alt={product_title} style={webStyle.dualSliderImg} />
                                                </div>
                                                <p style={webStyle.dualSliderTxt} className={classes.trendingBack2}>{product_title}</p>
                                            </div>
                                        )
                                    })}
                                </CustomSlider>
                            </div>
                        </div>
                        <div className={classes.itemSliderWrraper} >
                            <div className={classes.trendingTemplate}>
                                <CustomSlider {...settings}>
                                    {this.state.productListTwo?.map((item2, index2) => {
                                        const { product_title, product_image } = item2?.data?.attributes
                                        return (
                                            <div key={index2}>
                                                <div id="listTwo" onClick={() => this.handleTrendingProducts(item2.data.id)} style={webStyle.trendingProductsSlider}>
                                                    <img alt={product_title} src={product_image} style={webStyle.dualSliderImg} />
                                                </div>
                                                <p style={webStyle.dualSliderTxt} className={classes.dualSliderTxtBack}> {product_title}</p>
                                            </div>
                                        )
                                    })}
                                </CustomSlider>
                            </div>
                        </div>
                    </div>


                </div>
            </Box>
        );
    }

    render() {
        const { classes } = this.props;         
        const settings = {
            centerMode: false,
            dots: false,
            infinite: false,
            initialSlide: 0 ,
            speed: 500,
            slidesToShow: this.state.brandList.length >= 5 ? 5 : this.state.brandList.length,
            slidesToScroll: 1,
            boxShadow: "0px 1px 3px #EBEBEB",
            prevArrow: <ArrowBackIos style={{
                color: 'black',
                fontSize: '18px'
            }} data-test-id="back-arrow"/>,
            nextArrow: <ArrowForwardIos style={{
                color: 'black',
                fontSize: '18px'
            }} />,
            responsive: [
              {
                breakpoint: 1280,
                settings: {
                  slidesToShow: this.state.brandList.length > 4 ? 4 : this.state.brandList.length,
                  slidesToScroll: 1,
                  initialSlide: 0 
                }
              },
              {
                breakpoint: 960,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  initialSlide: 0
                  
                }
              },
              {
                breakpoint: 786,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  initialSlide: 0 
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  initialSlide: 0 ,
                  infinite: true
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 0 ,
                  infinite: true
                }
              }
            ],
          };
        const pagination = this.getResultCounts();
        return (
            <Box className={classes.mainWrraper}>
                <HeaderWeb navigation={this.props.navigation} id={this.props.id} />
                <Container className={classes.catalogContainer}>
                <Box className={classes.root} >
                    <Box style={{ zIndex: 99}}>
                    <nav className={classes.drawer} aria-label="mailbox folders">
                        <CategoriesSideBar 
                            data-test-id="categorySideBar" 
                            handleCustomFilter={this.handleCustomFilter}
                            handleUpdateUrl={this.handleUpdateUrl} 
                            colorFilter={this.state.color_filter} 
                            onSetColorFilter={this.handleSetColorFilter} 
                            navigation={undefined} id={"categories-sidebar"} 
                            subCategoryList={this.state.subCategoryList} 
                            brandList={this.state.brandList} 
                            setFilterValues={this.setFilterValues} 
                            subCatagoryItemsIds={this.state.subCatagoryItemsIds} 
                            brandItemsIds={this.state.brandItemsIds} 
                            productList={this.state.productList} 
                            max_price={this.state.max_price} 
                            min_price={this.state.min_price} 
                            customFieldsData={this.state.customFieldsData} 
                            customFieldValues={this.state.customFieldValues} 
                            handleRatingVlaue={this.handleRatingVlaue} 
                            out_of_stocks={this.state.out_of_stocks} 
                            onSetOutOfStocks={this.handleOutOfStocks} 
                            updatedRating={this.state.updatedRating} 
                            catagoryItemsIds={this.state.catagoryItemsIds} 
                            categoryList={this.state.categoryList}
                            miniCatagoryItemsIds={this.state.miniCatagoryItemsIds} 
                            miniCategoryList={this.state.miniCategoryList}
                            microCatagoryItemsIds={this.state.microCatagoryItemsIds} 
                            microCategoryList={this.state.microCategoryList}
                        />
                    </nav>
                    </Box>
                    <Box className={classes.content}>
                    {this.state.brandList && !this.renderTrendingProducts() &&  
                    <>
                        <Box>
                            <Container style={{ width: "100%", marginBottom: "10px"}}>
                                <Box className={classes.flex}>
                                <Typography data-test-id="redirect-to-home" onClick={this.handleHome} className={classes.category}>
                                    Home
                                </Typography>
                                <Typography className={classes.categoryOne}>{" / "} {this.state.productList.category}</Typography>
                                </Box>
                                <Typography
                                    className={classes.shopByText}>
                                    Shop by Brand
                                </Typography>
                                <Grid container spacing={2} style={{display:"block"}} data-test-id="slider-container" className={classes.shopByBrandContainer}>
                                    
                                    <ShopByBrandSlider {...settings}>
                                    {this.state.brandList && this.state.brandList.map((brand:IBrandList,index:any)=>{
                                        const isSingleBrand = this.state.brandList.length === 1;

                                        return <Grid data-test-id="brand-item-id" item key={`brand-list-${index}`} xs={12} sm={12} lg={12} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: isSingleBrand ? "flex-start" : "center" }} >
                                           <ClickableBox test-dataId="brand-option" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: isSingleBrand ? "flex-start" : "center"}} onClick={() => this.handleShopByBrandFilter(brand.id)}>
                                            <Box className={classes.brandBtn}>
                                            <img data-test-id="oppoRef" className={classes.brandImage} src={brand.attributes.brand_image ? brand.attributes.brand_image : logoImg} />
                                            </Box>
                                            <Typography className={classes.brandName}>{brand.attributes.brand_name}</Typography>
                                        </ClickableBox>
                                    </Grid>
                                    })}
                                    </ShopByBrandSlider>
                                </Grid>
                            
                            </Container>
                        </Box>
                            <Box className={classes.categoryFilterContainer} >
                                <Box className={classes.resultText}>
                                    {pagination.total>0 && <>Showing <span>{pagination.startIndex}</span>-<span>{pagination.endIndex}</span> of <span>{pagination.total}</span> results</>}
                                </Box>
                                <Box className={classes.categoryFilterBox}>
                                    <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: "row", marginLeft: 'auto' }} >
                                        <Grid item xs={6} >
                                            <FormControlTwo style={{ width: '100%' }}>
                                                <Box className={classes.displayFilter}>{"Display"}</Box>
                                                <Select 
                                                    style={{background:"#eee"}}
                                                    data-test-id="selectPerPaageref"
                                                    labelId="demo-simple-select-outlined-label"
                                                    defaultValue="50"
                                                    id="demo-simple-select-outlined"
                                                    value={this.state.itemPerPage}
                                                    onChange={this.handleItemPerPage}
                                                    inputProps={{
                                                        classes: {
                                                            select: classes.selectDisplay,
                                                        },
                                                    }}
                                                    MenuProps={{
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        PaperProps: {
                                                            style: {
                                                                border: "1px solid #b1b3b5",
                                                                padding: 0,
                                                                boxShadow: "none",
                                                                borderRadius: 5,
                                                                background: "white",
                                                                overflowY: "auto",
                                                                marginTop: "5px",
                                                                maxHeight: "400px",
                                                            }
                                                        },
                                                        getContentAnchorEl: null,
                                                    }}
                                                >
                                                    <MenuItemTwo value={50}>50 per Page</MenuItemTwo>
                                                    <MenuItemTwo value={40}>40 per page</MenuItemTwo>
                                                    <MenuItemTwo value={30}>30 per page</MenuItemTwo>
                                                </Select>
                                            </FormControlTwo>
                                        </Grid>
                                        <Grid item xs={6}>

                                            <FormControlTwo style={{ width: '100%' }}>
                                                <Box className={classes.displayFilter}>{"Sort By"}</Box>
                                                <Select 
                                                    style={{background:"#eee"}}
                                                    data-test-id="selectRecommonded"
                                                    labelId="demo-simple-select-outlined-label"
                                                    defaultValue="recommended"
                                                    value={this.state.sortByProduct}
                                                    onChange={this.handleSortByProductOption}
                                                    inputProps={{
                                                        classes: {
                                                            select: classes.selectDisplay,
                                                        },
                                                    }}
                                                    id="demo-simple-select-outlined"
                                                    placeholder="Recommonded"
                                                    MenuProps={{
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        PaperProps: {
                                                            style: {
                                                                border: "1px solid #b1b3b5",
                                                                padding: 0,
                                                                boxShadow: "none",
                                                                borderRadius: 5,
                                                                background: "white",
                                                                overflowY: "auto",
                                                                marginTop: "5px",
                                                                maxHeight: "400px",
                                                            }
                                                        },
                                                        getContentAnchorEl: null,
                                                    }}
                                                >
                                                    <MenuItemTwo value="recommended">
                                                        Recommended
                                                    </MenuItemTwo>
                                                    <MenuItemTwo value="whats_new">
                                                        What's New
                                                    </MenuItemTwo>
                                                    <MenuItemTwo value="popularity">Popularity</MenuItemTwo>
                                                    <MenuItemTwo value="customer_rating">Customer Rating</MenuItemTwo>
                                                    <MenuItemTwo value="high_to_low">High to Low</MenuItemTwo>
                                                    <MenuItemTwo value="low_to_high">Low to High</MenuItemTwo>
                                                </Select>
                                            </FormControlTwo>
                                        </Grid>
                                    </Grid>
                                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: "center", flexDirection: "column", margin: "0 10px" }}>
                                        {this.renderGrid()}
                                    </Box>                                    
                                </Box>
                            </Box>
                        <Divider />
                        </>}
                        <Grid container spacing={3} xs={12} className={classes.productWrapper}>
                            {this.renderProductList()}
                            {this.noProductsFound() && !this.renderTrendingProducts() &&
                                <Box className={classes.noProductsBox} id="noProduct">
                                    <Typography className={classes.noProductsText}>
                                        No Products found
                                    </Typography>
                                </Box>
                            }
                        </Grid>
                        <Grid container spacing={3} xs={12} style={webStyle.paginationWrap}>
                        {this.renderProdPagination()}
                        </Grid>
                        {this.renderTrendingProducts() && this.renderTrending()}
                    </Box>
                </Box>
                </Container>
                {/* Footer */}
                <FooterWeb navigation={this.props.navigation} id={this.props.id} categories={[]} token={""} />
            </Box >
        )
    }
    // Customizable Area End
}

// Customizable Area Start
const styles = (theme: Theme) => ({
    root: {
        fontFamily: `"Lato", sans-serif`,
        display: 'flex',
        flexDirecton: 'row' as const,
        flexWrap: 'nowrap' as const,
        backgroundColor: "#fefaf9",
        padding:'20px',
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            flexDirection: "column" as const
        }
    },
    categoryFilterContainer: {
        display: 'flex', 
        width: '100%', 
        justifyContent: 'space-between', 
        flexDirection: "row" as const, 
        marginBottom:'15px',
        alignItems:'end',
        [theme.breakpoints.down('xs')]:{
            width: "100%"
        }
      },
      resultText:{
        color: "#666666", 
        fontFamily: `"Lato", sans-serif`, 
        fontWeight: 400, 
        fontSize: 12, 
        paddingBottom:"6px",
        "& span":{
            fontWeight:"bold"
        }
      },
    categoryFilterBox: {
        display:"flex",
        marginLeft:"auto",
        width:"450px"
    },
    mainWrraper: {
        background: "#fff",
        width: "100%",
        overflowX: "hidden" as const,
    },
    brandContainer: {
        margin: "20px 0px",
    },
    brandBtn: {
        height: "90px",
        width: "90px",
        borderRadius: "50%",
        border: "none",
        background: "white",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        padding: "10px",
    },
    drawer: {
        padding:"20px 0 0 20px",
        [theme.breakpoints.up('sm')]: {
            width: 240,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: 240,
    },
    content: {
        width: 'auto' as const,
        overflowX: 'auto' as const,
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(1)
        }
    },
    contentWrapper: {
        overflowY: "auto" as const,
        overflowX: "hidden" as const
    },
    headerText: {
        padding: "20px 0px 15px 0px",
        fontSize: "18px",
        fontWeight: 400 as const,
        fontFamily: `"Lato", sans-serif` as const
    },
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    orderTextWrapper: {
        width: "100%",
        display: "flex",
        marginBottom: 10,
        flexDirection: "row" as const,
        flexWrap: "wrap" as const,
        justifyContent: "space-between",
        alignItems: "center" as const,
    },
    lAndIContainer: {
        display: "flex",
        flexDirection: "column" as const,
    },
    searchWrapper: {
        padding: "20px 0px 15px 0px"
    },
    searchOrderText: {
        fontSize: '12px',
        fontWeight: 400 as const,
        color: '#33333A',
    },
    container: {
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column" as const,
        padding: "30px",
        position: "relative" as const,
        background: "white"
    },
    searchBoxWrapper: {
        position: 'relative' as const,
        width: '65%',
    },
    options: {
        width: "250px !important",
        borderRadius: "5px",
        background: "white",
    },
    label: {
        fontSize: "12px",
        fontFamily: '"Lato",san-serif' as const,
    },
    select: {
        paddingLeft: "8px",
        width: "150px",
        height: "40px",
        borderRadius: "5px",
        marginTop: "10px",
        background: "grey",
        paddingRight: "8px",
        fontSize: "14px",
        border: "1px solid grey"
    },
    ordersText: {
        fontSize: "27px",
        fontWeight: 700 as const,
        fontFamily: `"Lato", sans-serif` as const
    },
    brandImage: {
        width:'100%',
        objectFit:'fill' as const,
        borderRadius:'50%'
    },
    category: { 
        fontFamily: `"Lato", sans-serif`, 
        fontSize: "14px", 
        color: "#585858", 
        cursor:'pointer' 
    },
    shopByText: { 
        fontFamily: `"Lato-Heavy", sans-serif`, 
        marginTop: "20px", 
        fontSize: "32px", 
        color: "#33333a", 
        fontWeight: 400 
    },
    brandName: { 
        fontFamily: `"Lato", sans-serif`,
        marginTop: "10px", 
        color: "#33333a", 
        textAlign: "center" as const, 
        fontSize: "16px", 
        fontWeight: 400 
    },
    categoryOne: { 
        fontFamily: `"Lato", sans-serif`, 
        fontSize: "14px", 
        color: "#585858", 
    },
    shopByBrandContainer: {
        padding: "16px 32px",
        '& .slick-current': {
            paddingLeft: 40,
            [theme.breakpoints.down('xs')]:{
                padding: 10
            },
        },
        '& .slick-list': {
            height: 180,
            [theme.breakpoints.down('xs')]:{
                height: 180,
            }
        },
    },
    flex:{
        display: 'flex',
        [theme.breakpoints.down('xs')]:{
            marginTop: 24,
        }
    },
    catalogContainer: { 
        maxWidth: "1700px", 
        padding: "0", 
        margin: "0 auto" 
      },
      selectDisplay: {
        fontSize: "16px !important",
        fontFamily: `"Lato", sans-serif !important` as const,
        color: "#657A8E !important",
        fontWeight: 400,
      },
      displayFilter: { 
        color: "#666666", 
        fontFamily: `"Lato", sans-serif`, 
        fontWeight: 400, 
        fontSize: 12, 
        marginBottom: 10 
    },
    productWrapper: {
        marginTop: "10px",
        '@media (max-width:600px)': {
            margin: '0 auto'
        }
    },
    noProductsBox: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5%"
    },
    noProductsText: {
        color: "#33333a",
        fontSize: "20px",
        marginTop: "20px",
        fontFamily: '"Lato-Heavy", sans-serif',
        fontWeight: 400
    },
    itemSliderWrraper: {
        [theme.breakpoints.up("sm")]: {
            flexDirection: 'row' as const,
            gap: '20px',
        },
    },
    trandingTemlateImg: {
        width: "200px",
        height: "350px",
        margin: 'auto',
        display: "inline-block",
        borderRadius: "8px",
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    trendingBack2: {
        background: "#E6E8F4"
    },
    dualSliderTxtBack: {
        background: "#E1EFFD"
    },
    trendingTemplate: {
        flex: 5, 
        width: '100%', 
        height: "350px",
        display: "inline-block",
        borderRadius: "8px",
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          margin: 'auto'
        }
      },
      pagination: {
        justifyContent: "center",
        marginTop: "15px",
        display: "flex",
        "& .MuiPaginationItem-root.Mui-selected": {
            backgroundColor: "#fff",
            color: "#ff6008",
            borderColor: "#f78e24"
          },
        '&.MuiPaginationItem-outlinedPrimary.Mui-selected': {
            color: '#ff6008',
            backgroundColor: '#fff'
        }
    }
});

const webStyle = {
    trending: {
      color: "#33333a",
      fontFamily: `"Lato", sans-serif`,
      fontSize: "26px",
      fontWeight: 600,
    },
    trendingWrapper: {
        display: "flex",
        flexDirection: "column" as const,
        gap: "45px"
    },
    trendingProductsSlider: {
      height: "290px",
      borderRadius: "10px",
      backgroundColor: "#fff",
      cursor: 'pointer',
      boxShadow: "0px 1px 3px #EBEBEB",
    },
    dualSliderImg: {
      height: "100%",
      width: "100%",
      objectFit: "cover" as const
    },
    dualSliderTxt: {
      border: "1px solid white",
      borderRadius: "5px",
      margin: "7px 0 0",
      textAlign: "center" as const,
      textTransform: "capitalize" as const,
      fontFamily: `"Lato", sans-serif`,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap" as const,
      fontSize: "14px",
      padding: '16px 20px',
    },
    paginationWrap: {
        display:'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
  };

  const CustomSlider = styled(Slider)({
    "& .slick-slide > div > div": {
      borderRadius: 10
    },
    "& .slick-slide > div": {
      display: "flex",
      margin: "0 5px",
      gap: 10
    },
    "& .slick-slide": {
      height: "unset"
    },
    "& .slick-prev": {
      top: -25,
      left: "unset",
      right: 50,
      color: "#000",
      backgroundColor: "#fff",
      padding: "5px",
      fontSize: "18px",
      borderRadius: '50%',
    },
    "& .slick-next": {
      top: -25,
      left: "unset",
      right: 0,
      color: "#000",
      backgroundColor: "#fff",
      padding: "5px",
      fontSize: "18px",
      borderRadius: '50%',
      "& >path": {
      }
    },
    "& .slick-prev::before": {
      fontSize: 35,
      opacity: 1,
      color: "rgb(255, 255, 255)"
    },
    "& .slick-next::before": {
      fontSize: 35,
      opacity: 1,
      color: "rgb(255, 255, 255)"
    }
  });

export { ProductCatalogue }

export default withRouter(withStyles(styles)(ProductCatalogue) as any) as any;  
// Customizable Area End